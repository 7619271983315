import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';

import { HistoryComponent } from '@shared/components/history/history.component';
import { StoreContactComponent } from '@shared/components/td-store/store-contact/store-contact.component';
import { StoreLocationComponent } from '@shared/components/td-store/store-location/store-location.component';
import { FormChangeStatus } from '@shared/enum/form-change-status.enum';
import { AuditLog, HistoryType } from '@shared/models/audit-log.model';
import { findObjectDifference } from '@shared/utils/findObjectDifference.util';

import { environment as env } from '../../../../environments/environment';
import { OrderPolicyComponent } from '../../../shared/components/td-store/order-policy/order-policy.component';
import { PreOrderPolicyComponent } from '../../../shared/components/td-store/order-policy/pre-order-policy/pre-order-policy.component';
import { StoreConditionComponent } from '../../../shared/components/td-store/store-condtion/store-condition.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  ErrorResponse,
  StorePageTabs,
  StoreProfile,
  StoreRequestListSearchCriteria,
  StoreRequestTemplate,
  StoreRequestViewResponse,
  StoreTabs,
  TaskModuleUrl
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import {
  ResetStoreRequestSelected,
  StoreApproveRequestAction,
  StoreCreateResetAction,
  StoreCreateSaveRequestAction,
  StoreCreateSubmitRequestAction,
  StoreMerchantByIdRequestAction,
  StoreRequestByIdRequestAction,
  StoreRequestHistoryRequestAction,
  StoreRequestHistoryResponseAction,
  StoreRequestListRequestAction
} from '../../../shared/store/actions/store-request.actions';
import { StoreCreateResponseState } from '../../../shared/store/reducers/store-create.reducers';
import { selectStore, selectStoreApproveRejectStatus } from '../../../shared/store/selectors/store-create.selectors';
import {
  selectStoreRequestById,
  selectStoreRequestHistory,
  selectStoreRequestListCriteria
} from '../../../shared/store/selectors/store-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../shared/utils/date-util';
import { getFileId, getMultipleFileId } from '../../../shared/utils/get-fileId-util';
import { merchantColorStatus } from '../../../shared/utils/merchant-color-status-util';
import { removeObjectArray } from '../../../shared/utils/remove-object-array.util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-store-request',
  templateUrl: './store-request.component.html',
  styleUrls: ['./store-request.component.scss']
})
export class StoreRequestComponent extends OnDestroyMixin implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('storeProfile') storeProfile: StoreProfileComponent;
  @ViewChild('storeLocation') storeLocation: StoreLocationComponent;
  @ViewChild('storeContact') storeContact: StoreContactComponent;
  @ViewChild('storeCondition') storeCondition: StoreConditionComponent;
  @ViewChild('orderPolicy') orderPolicy: OrderPolicyComponent;
  @ViewChild('preOrderPolicy') preOrderPolicy: PreOrderPolicyComponent;

  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    merchant?: string;
    storeId?: string;
    originPage?: string;
  };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  protected storeTabList = [{ label: 'Store Profile', value: StoreTabs.STORE_PROFILE }];
  protected selectedTab: StoreTabs = StoreTabs.STORE_PROFILE;

  public saved: boolean;
  public page: TDStorePage;
  public type: RequestTypeEnum;
  public step: RequestStepEnum;
  public status: RequestStatusEnum;
  public taxID: string;
  public storeForm: UntypedFormGroup;
  public storeView$: Observable<StoreRequestViewResponse>;
  public submitted: boolean;
  public listOfChange: any = {};

  dataTemp: StoreRequestViewResponse;
  auditLogs$: Observable<AuditLog[]>;
  private isApprove: boolean;
  private localStore: Observable<any>;
  private listSearchCriteria: StoreRequestListSearchCriteria;
  private requestNo: string;

  onViewInitEffect = new BehaviorSubject<boolean>(false);
  onTriggerEditEffect = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected readonly modalService: BsModalService,
    protected spinner: NgxSpinnerService,
    private readonly translate: TranslateService,
    protected storeRequestService: StoreRequestService
  ) {
    super();
  }

  ngOnInit() {
    this.initControl();
    this.initState();

    combineLatest([this.onViewInitEffect, this.onTriggerEditEffect])
      .pipe(
        filter(([value1, value2]) => value1 && value2),
        take(1)
      )
      .subscribe(() => {
        this.onTriggerEdit();
      });
  }

  ngAfterViewInit() {
    this.onViewInitEffect.next(true);
  }

  ngOnDestroy() {
    this.store.dispatch(new StoreCreateResetAction());
    this.store.dispatch(new ResetStoreRequestSelected());

    super.ngOnDestroy();
  }

  refreshStoreList(isResetToFirstPage = false) {
    if (isResetToFirstPage) {
      this.listSearchCriteria = {
        ...this.listSearchCriteria,
        page: 0
      };
    }

    if (this.data && this.data.originPage !== TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new StoreRequestListRequestAction(this.listSearchCriteria));
    }
  }

  get storePageTabs() {
    return StorePageTabs;
  }

  get TDStorePage() {
    return TDStorePage.STORE_REQUEST;
  }

  get storePageMode() {
    return RequestPageModesEnum;
  }

  get profile() {
    return this.storeForm.get('storeProfile')['controls'][0] as UntypedFormGroup;
  }

  get location() {
    return this.storeForm.get('storeLocation') as UntypedFormGroup;
  }

  get storeFormArray() {
    return this.storeForm.get('storeProfile') as UntypedFormArray;
  }

  getColorStatus(status: string): string {
    return merchantColorStatus(status);
  }

  initState() {
    this.page = TDStorePage.STORE_REQUEST;
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new StoreRequestByIdRequestAction({ storeId: this.data.storeId }));
    } else if (RequestPageModesEnum.REQUEST_CREATE === this.data.mode) {
      this.store.dispatch(new StoreMerchantByIdRequestAction({ merchant: this.data.merchant }));
    }

    // Current searchCriteria
    this.localStore
      .pipe(select(selectStoreRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore.pipe(select(selectStore)).subscribe((data: StoreCreateResponseState) => {
      if (data && data.result && data.result.response) {
        this.alertSuccessModal(this.getSubmitMessage(data.isSave));
      }

      if (RequestPageModesEnum.REQUEST_EDIT === this.data.mode) {
        this.onTriggerEditEffect.next(true);
      }
    });
    this.auditLogs$ = this.localStore.pipe(select(selectStoreRequestHistory));
    this.storeView$ = this.localStore.pipe(select(selectStoreRequestById));
    this.storeView$
      .pipe(
        filter(value => Boolean(value)),
        tap(value => {
          this.type = value.type || RequestTypeEnum.NEW;
          this.status = value.status || RequestStatusEnum.DRAFT;
          this.step = value.step || RequestStepEnum.PROFILE;
          this.requestNo = value.requestNo;
          this.taxID = value.taxId;

          this.storeFormArray.controls.forEach(item => {
            item.get('taxNo').setValue(this.taxID);
          });
          if (value.type === RequestTypeEnum.EDIT) {
            const tempStoreProfile = value.merchantInfo.storeProfile.map(item => {
              return {
                ...item,
                openDate: item.openDate ? formatDateStartOfDay(item.openDate, env.dateISO8601) : null
              };
            });

            this.dataTemp = { ...value, merchantInfo: { ...value.merchantInfo, storeProfile: tempStoreProfile } };
            this.listOfChange = this.getListOfChanges(value.updatedFields);
          }
        })
      )
      .subscribe();
  }

  getListOfChanges(updatedFields: string[]) {
    return (
      updatedFields?.reduce((listOfChanges: any, field: string) => {
        const [group, key] = field.split('.');
        listOfChanges[group] = { ...listOfChanges[group], [key]: true };
        return listOfChanges;
      }, {}) || {}
    );
  }

  initControl() {
    this.storeForm = this.fb.group({});
  }

  onSave() {
    this.saved = true;
    const storeProfile = this.storeForm.getRawValue().storeProfile[0];

    // require storeCode and storeName before saved
    if (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      (!storeProfile.storeCode || !storeProfile.storeName)
    ) {
      return;
    }

    this.store.dispatch(new StoreCreateSaveRequestAction(this.prepareStoreRequest()));
  }

  onSubmit() {
    this.submitted = true;

    if (this.storeProfile.onStoreCodeAsyncValidateStatus$.value === FormChangeStatus.PENDING) {
      this.storeProfile.onStoreCodeAsyncValidateStatus$
        .pipe(
          filter(status => status === FormChangeStatus.VALID || status === FormChangeStatus.INVALID),
          take(1)
        )
        .subscribe(_ => {
          setTimeout(() => {
            this.doBeforeSubmit();
          }, 0);

          this.storeProfile.onStoreCodeAsyncValidateStatus$.next(null);
        });
    } else {
      this.doBeforeSubmit();
    }
  }

  doBeforeSubmit() {
    if (this.storeForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  onCancel() {
    if (this.storeForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const storeRequestTemplate = this.prepareStoreRequest();
          this.store.dispatch(new StoreCreateSubmitRequestAction(storeRequestTemplate));
        }
      });
  }

  prepareStoreRequest(): StoreRequestTemplate {
    let storeRequestTemplate = new StoreRequestTemplate();
    const formData = this.storeForm.getRawValue();
    const store = formData.storeProfile[0];
    const location = formData.storeLocation;
    const contact = formData.storeContact;
    let storeProfileData = new StoreProfile();
    const storePhoneData = index => contact?.at(index)?.phone;
    const storeContactData = index => contact?.at(index)?.contactName;
    this.storeView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          storeRequestTemplate = {
            ...storeRequestTemplate,
            id: value.id,
            step: value.step,
            version: value.version
          };

          if (value.merchantInfo) {
            storeProfileData = value.merchantInfo.storeProfile[0];
          }
        })
      )
      .subscribe();

    storeRequestTemplate.merchantNo = this.data.merchant;
    storeRequestTemplate.storeProfile = {
      ...store,
      ...location,
      openDate: store.openDate ? formatDateStartOfDay(store.openDate, env.dateISO8601) : null,
      storeCode: store.storeCode && store.storeCode.toUpperCase(),
      storeNo: storeProfileData && storeProfileData.storeNo,
      contactName: storeContactData(0) ?? null,
      contactName2: storeContactData(1) ?? null,
      contactName3: storeContactData(2) ?? null,
      phone: storePhoneData(0)?.nationalNumber?.replace(/ /g, '') ?? null,
      phone2: storePhoneData(1)?.nationalNumber?.replace(/ /g, '') ?? null,
      phone3: storePhoneData(2)?.nationalNumber?.replace(/ /g, '') ?? null,
      countryCode: storePhoneData(0)?.internationalNumber.split(' ')[0] ?? null,
      countryCode2: storePhoneData(1)?.internationalNumber.split(' ')[0] ?? null,
      countryCode3: storePhoneData(2)?.internationalNumber.split(' ')[0] ?? null,
      rentalFee:
        !this.location.controls.rentalFee.disabled && location.rentalFee
          ? { amount: location.rentalFee, currency: 'THB' }
          : null,
      poiType: location.poiType && location.poiType.join(','),
      storeFrontPicture: location.storeFrontPicture && getMultipleFileId(location.storeFrontPicture),
      storeFrontVideo: getFileId(location.storeVDOFront) ?? null,
      titleDeed: getFileId(location.titleDeed) ?? null,
      houseRegistrationPicture: getFileId(location.houseRegistrationPicture) ?? null,
      idCardPicture: getFileId(location.idCardPicture) ?? null,
      consentLetterPicture: getFileId(location.consentLetterPicture) ?? null,
      attachmentPicture: location.attachmentPicture && getMultipleFileId(location.attachmentPicture),
      deliveryTimes: removeObjectArray(location.deliveryTimes),
      nearByPois: removeObjectArray(location.nearByPois),
      nearByCompetitors: removeObjectArray(location.nearByCompetitors),
      storeConditions: formData.storeConditions,
      terminateDate: store?.terminateDate ? formatDateStartOfDay(store.terminateDate, env.dateISO8601) : null
    };
    formData.orderSchedule.orderScheduleList[0].schedules = removeObjectArray(
      formData.orderSchedule.orderScheduleList[0].schedules
    );

    formData.orderSchedule.orderScheduleList[0].preOrderSchedules = removeObjectArray(
      formData.preOrderSchedule.preOrderScheduleList[0].preOrderSchedules
    );
    formData.orderSchedule.orderScheduleList[0].preOrderWarehouseCode =
      formData.preOrderSchedule.preOrderScheduleList[0].preOrderWarehouseCode;

    storeRequestTemplate.orderSchedule = formData.orderSchedule.orderScheduleList[0];

    if (this.type === RequestTypeEnum.EDIT) {
      const storeProfileInfo = this.dataTemp?.merchantInfo?.storeProfile[0];
      const storeProfile = {
        ...storeProfileInfo,
        openDate: formatDateStartOfDay(storeProfileInfo?.openDate, env.dateISO8601),
        terminateDate: storeProfileInfo?.terminateDate
          ? formatDateStartOfDay(storeProfileInfo?.terminateDate, env.dateISO8601)
          : null
      };

      storeRequestTemplate.updatedFields = [
        ...(this.dataTemp.updatedFields || []),
        ...findObjectDifference('storeProfile', storeProfile, storeRequestTemplate.storeProfile)
      ];
    }
    return storeRequestTemplate;
  }

  doApproveRejectRequest(isApprove: boolean) {
    this.isApprove = isApprove;

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          this.store.dispatch(
            new StoreApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
          );

          return this.localStore.pipe(select(selectStoreApproveRejectStatus));
        }),
        filter(isApproveRejectSuccess => isApproveRejectSuccess)
      )
      .subscribe({
        next: () => {
          this.alertApproveRejectModalSuccess({
            result: {
              response: 'success',
              errorResponse: null
            }
          });
        },
        error: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  cancelStoreRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The request has been cancelled.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  deleteStoreRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    const modal = confirmModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        mergeMap(() => {
          return this.storeRequestService
            .deleteByRequestId({ storeId: this.data.storeId })
            .pipe(untilComponentDestroyed(this));
        })
      )
      .subscribe({
        next: () => {
          this.alertSuccessModal('The request has been deleted.');
        },
        error: error => {
          this.alertErrorModal(error.error);
        },
        complete: () => {
          if (modal) {
            modal.unsubscribe();
          }
        }
      });
  }

  alertApproveRejectModalSuccess(resp) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
        this.refreshStoreList(this.status === RequestStatusEnum.DRAFT);
      }

      if (alertModal.content.action) {
        alertModal.content.action.unsubscribe();
      }
    });
  }

  hasSavePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSavePermission(this.type, this.page, this.step, this.status);
  }

  hasEditStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, this.status);
  }

  hasSubmitStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasApproveStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasApprovePermission(this.type, this.page, this.step, this.status);
  }

  hasCancelPermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasCancelPermission(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasDeletePermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasDeletePermission(this.type, this.page, this.step, this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditStorePermission() ||
      this.hasApproveStorePermission() ||
      this.hasSubmitStorePermission()
    );
  }

  onTriggerEdit() {
    this.data.title = 'Edit Store Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(this.page)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, editSection)) {
      this.storeProfile.toggleEditStoreProfile();
      this.storeContact.toggleEditStoreContact();
      this.storeLocation.toggleEditStoreLocation();
      this.storeCondition.toggleEditStoreCondition();
    }

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.ORDER_POLICY)) {
      this.scrollTo(RequestSectionEnum.ORDER_POLICY);
      this.orderPolicy.toggleEditOrderPolicy();
      this.preOrderPolicy.toggleEditPreOrderPolicy();
    }
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
        this.refreshStoreList(this.status === RequestStatusEnum.DRAFT);
      }

      if (alertModal.content.action) {
        alertModal.content.action.unsubscribe();
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  scrollTo(anchor: RequestSectionEnum) {
    const element = document.getElementById(anchor);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
    }
  }

  getSubmitMessage(isSave: boolean): string {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === RequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.step === RequestStepEnum.ORDER_POLICY) {
      return 'The store has been created.';
    } else {
      return 'The request has been updated.';
    }
  }

  hasViewHistoryPermission(status: RequestStatusEnum): boolean {
    return (
      this.tdStoreWorkflowUtil.hasViewHistoryPermission(status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  showHistory() {
    this.store.dispatch(new StoreRequestHistoryRequestAction({ storeId: this.data.storeId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${this.requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE_REQUEST,
      auditLogs$: this.auditLogs$
    };

    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  isSelectedTab(tab: StoreTabs) {
    return this.selectedTab === tab;
  }

  onSelectTabs(tab: StoreTabs) {
    this.selectedTab = tab;
  }
}
