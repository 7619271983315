import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GoodsReceive,
  GoodsReceiveCancelRequestData,
  GoodsReceiveExportCriteria,
  GoodsReceivePagination,
  GoodsReceiveSearchCriteria
} from '../models/goods-receive.model';
import { MetricAction, MetricActionType, MetricModule, MetricType, Metrics } from '../models/metrics';
import { BaseService } from './base.service';

@Injectable()
export class GoodsReceiveService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.goodsReceive;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: GoodsReceiveSearchCriteria): Observable<GoodsReceivePagination> {
    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.GR,
          route: this.envService.url,
          action: MetricActionType.SEARCH
        }
      ]
    };
    const params = this.getParams(criteria, true);
    return this.http.get<GoodsReceivePagination>(this.getUrl(), {
      headers: this.loaderHeaders(null, metricsReq),
      observe: 'body',
      params
    });
  }

  public submit(gr: GoodsReceive): Observable<GoodsReceive> {
    const url = this.getUrl(); // OR  this.getFullUrl(this.envService.submit);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.PO,
          path: window.location.pathname
        },
        {
          metricType: MetricType.HISTOGRAM,
          module: MetricModule.PO,
          action: MetricAction.CREATE,
          type: gr.shipToType,
          items: gr.items.length
        }
      ]
    };

    return this.http.post<GoodsReceive>(url, gr, {
      headers: this.loaderHeaders(3000, metricsReq),
      observe: 'body'
    });
  }

  public getGrByPoNo(poNo: string): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.getByPoNo, {
      poNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getGrById(id: string): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.getById, {
      id
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public cancel(grCancelRequest: GoodsReceiveCancelRequestData): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<GoodsReceive>(url, grCancelRequest, {
      headers: this.loaderHeaders(3000),
      observe: 'body'
    });
  }

  public exportGr(criteria: GoodsReceiveExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportGr);

    const metricsReq: Metrics = {
      metrics: [
        {
          metricType: MetricType.COUNTER,
          module: MetricModule.GR,
          path: `${this.envService.url}${this.envService.exportGr}`,
          action: MetricActionType.EXPORT
        },
        {
          metricType: MetricType.RESPONSE_TIME,
          module: MetricModule.GR,
          route: `${this.envService.url}${this.envService.exportGr}`,
          action: MetricActionType.EXPORT
        }
      ]
    };

    return this.http.post<GoodsReceive>(url, criteria, {
      headers: this.loaderHeaders(0, metricsReq),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
