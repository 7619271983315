import env from './.env';
import { environment as defaultEnv } from './base.environment';

export const environment = {
  ...defaultEnv,
  hmr: false,
  metrics: true,
  notifyError: true,
  version: env.version + '-redseries',
  tier: 'redseries',
  serverUrl: 'https://api-red.tdshop.io',
  storeResourcesPath: '/store-resources-red',
  cookies: {
    ...defaultEnv.cookies,
    domain: env.forceDomain || 'nest-red.tdshop.io'
  }
};
